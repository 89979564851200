import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@services/modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private translate: TranslateService,
    private modalService: ModalService,
  ) {}

  public showError(error: HttpErrorResponse, closePreviousModal = true): void {
    if (error?.error?.status === 'expired') this.translate.get('errors.expired')
        .subscribe(data => this.modalService.showMsg(data, null, {closePreviousModal}));

    else if (error?.error?.status === 'booked') this.translate.get('errors.booked')
        .subscribe(data => this.modalService.showMsg(data, null, {closePreviousModal}));

    // @ts-ignore
    else if (error?.type === 'validation_error') this.modalService.showMsg(error.message, null, {closePreviousModal});
    // @ts-ignore
    else if (error?.type === 'invalid_request_error') this.modalService.showMsg(error.message, null, {closePreviousModal});
    else if (error?.error?.error) {
      const msg = error.error.error;
      this.modalService.showMsg(msg, null, {closePreviousModal});
      return;
    }
    else if (error?.error?.errors) {
      let msg = error.error.message;
      for (const [key, value] of Object.entries(error.error.errors)) {
        for (const additionalMsg of value as Array<string>) {
          msg += ` ${additionalMsg}`;
        }
      }
      this.modalService.showMsg(msg, null, {closePreviousModal});

    } else {
      if (typeof error?.error === 'string') {
        this.modalService.showMsg(error.error, null, {closePreviousModal});
        return;
      }
      if (typeof error === 'string') {
        this.modalService.showMsg(error, 600, {closePreviousModal});
        return;
      }
      const errorText = error?.error?.message || 'Unexpected error on server';
      this.modalService.showMsg(errorText, null, {closePreviousModal});
    }
  }
}
