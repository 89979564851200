import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatDialogModule} from '@angular/material/dialog';
import {MaterialModalComponent} from '@components/common/modals/material-modal/material-modal.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {SlickCarouselModule} from 'ngx-slick-carousel';

const SHARED_MODULES = [
  CommonModule,
  TranslateModule,
  FontAwesomeModule,
  NgxPageScrollModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
  MatDialogModule,
  MatExpansionModule,
  SlickCarouselModule,
];

const SHARED_COMPONENTS = [
  MaterialModalComponent,
];


@NgModule({
    imports: [
        ...SHARED_MODULES
    ],
  declarations: [
    ...SHARED_COMPONENTS,
  ],

  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
  ],
})
export class SharedModule { }
