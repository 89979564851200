import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {CookieService} from 'ngx-cookie';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    private cookie: CookieService,
  ) {
    const lang = cookie.get('dWLang') || translate.currentLang;
    translate.setDefaultLang(lang);
    translate.use(lang);
    this.localize.changeLanguage(lang);
  }
}
