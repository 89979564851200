<div class="container">
  <div class="terms-wrap">
    <h1>{{'landing.terms.title' | translate}}</h1>
    <h2>{{'landing.terms.subtitle1' | translate}}</h2>
    <p>{{'landing.terms.thisTerms' | translate}}</p>

    <h2>{{'landing.terms.userData' | translate}}</h2>
    <p>{{'landing.terms.userDataP1' | translate}}</p>
    <p>{{'landing.terms.userDataP2' | translate}}</p>

    <h2>{{'landing.terms.broadcasting' | translate}}</h2>
    <p>{{'landing.terms.broadcastingP' | translate}}</p>

    <h2>{{'landing.terms.responsibilities' | translate}}</h2>
    <p>{{'landing.terms.responsibilitiesP1' | translate}}</p>
    <p>{{'landing.terms.responsibilitiesP2' | translate}}</p>
    <p>{{'landing.terms.responsibilitiesP3' | translate}}</p>
    <p>{{'landing.terms.responsibilitiesP4' | translate}}</p>

    <h2>{{'landing.terms.software' | translate}}</h2>
    <p>{{'landing.terms.softwareP' | translate}}</p>

    <h2>{{'landing.terms.beta' | translate}}</h2>
    <p>{{'landing.terms.betaP' | translate}}</p>

    <h2>{{'landing.terms.ourData' | translate}}</h2>
    <p>{{'landing.terms.ourDataP' | translate}}</p>

    <h2>{{'landing.terms.copyright' | translate}}</h2>
    <p>{{'landing.terms.copyrightP' | translate}}</p>

    <h2>{{'landing.terms.paid' | translate}}</h2>
    <p><strong>{{'landing.terms.payments' | translate}}. </strong>{{'landing.landing.terms.paymentsP' | translate}}</p>
    <p><strong>{{'landing.terms.ref' | translate}}. </strong>{{'landing.terms.refP' | translate}}</p>
    <p><strong>{{'landing.terms.downgrades' | translate}}. </strong>{{'landing.terms.downgradesP' | translate}}</p>
    <p><strong>{{'landing.terms.changes' | translate}}. </strong>{{'landing.terms.changesP' | translate}}</p>

    <h2>{{'landing.terms.termination' | translate}}</h2>
    <p>{{'landing.terms.terminationP1' | translate}}</p>
    <p>{{'landing.terms.terminationP2' | translate}}</p>
    <p>{{'landing.terms.terminationP3' | translate}}</p>
    <p>{{'landing.terms.terminationP4' | translate}}</p>
    <p>{{'landing.terms.terminationP5' | translate}}</p>
    <p>{{'landing.terms.terminationP6' | translate}}</p>
    <p>{{'landing.terms.terminationP7' | translate}}</p>
    <p>{{'landing.terms.terminationP8' | translate}}</p>
    <p>{{'landing.terms.terminationP9' | translate}}</p>

    <h2>{{'landing.terms.discontinuation' | translate}}</h2>
    <p>{{'landing.terms.discontinuationP' | translate}}</p>

    <h2>{{'landing.terms.services' | translate}}</h2>
    <p>{{'landing.terms.servicesP' | translate}}</p>

    <h2>{{'landing.terms.limitation' | translate}}</h2>
    <p>{{'landing.terms.limitationP1' | translate}}</p>
    <p>{{'landing.terms.limitationP2' | translate}}</p>
    <p>{{'landing.terms.limitationP3' | translate}}</p>
    <p>{{'landing.terms.limitationP4' | translate}}</p>
    <p>{{'landing.terms.limitationP5' | translate}}</p>
    <p>{{'landing.terms.limitationP6' | translate}}</p>
    <p>{{'landing.terms.limitationP7' | translate}}</p>

    <h2>{{'landing.terms.resolving' | translate}}</h2>
    <p>{{'landing.terms.resolvingP' | translate}}</p>
    <p>{{'landing.terms.resolvingP2' | translate}}</p>

    <h2>{{'landing.terms.entire' | translate}}</h2>
    <p>{{'landing.terms.entireP' | translate}}</p>

    <h2>{{'landing.terms.waiver' | translate}}</h2>
    <p>{{'landing.terms.waiverP' | translate}}</p>

    <h2>{{'landing.terms.modifications' | translate}}</h2>
    <p>{{'landing.terms.modificationsP1' | translate}}</p>
    <p>{{'landing.terms.modificationsP2' | translate}}</p>
    <p>{{'landing.terms.modificationsP3' | translate}}</p>
    <p>{{'landing.terms.modificationsP4' | translate}}</p>
    <p>{{'landing.terms.modificationsP5' | translate}}</p>
    <p>{{'landing.terms.modificationsP6' | translate}}</p>

  </div>
</div>
