<p class="description">{{data.msg}}</p>

<!--<div class="msg-list">-->
<!--  <ul *ngIf="data.list?.length > 1">-->
<!--    <li *ngFor="let msg of data.list">{{msg}}</li>-->
<!--  </ul>-->
<!--</div>-->

<div class="modal-buttons-wrap">
        <button class="dw-btn dw-btn_cancel" *ngIf="data.buttonsProps.cancel" mat-button [mat-dialog-close]="false">
          {{('app.buttons.' + (data.buttonsProps?.cancelTitle || 'cancel') | translate)}}
        </button>
        <button class="dw-btn" mat-button [mat-dialog-close]="true">
          {{('app.buttons.' + (data.buttonsProps?.submitTitle || 'ok') | translate)}}
        </button>
</div>
