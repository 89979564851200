<div class="main">
  <div class="main_wrap">
    <div class="background">404</div>

    <div class="number-wrap">
      <div class="center">4</div>
      <div class="center">0</div>
      <div class="center">4</div>
    </div>
    <p class="description">{{'landing.notFound.title' | translate}}</p>
    <button class="button_blue" (click)="backOnPrevUrl()">{{'landing.notFound.button' | translate}}</button>
  </div>
</div>

<div class="dots square_dots"></div>
<div class="dots second_dots"></div>


