import {BrowserModule,Meta, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {translateHttpFactory} from '@shared/i18n/translation.loader';
import {DwMissingTranslationHandler} from '@shared/i18n/dwMissingTranslationHandler';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader
} from '@gilsdav/ngx-translate-router';
import {Location} from '@angular/common';
import {SharedModule} from './modules/shared/shared.module';
import {CookieModule} from 'ngx-cookie';
import {NotFoundPage} from '@components/common';
import {ModalService} from '@services/modal/modal.service';
import {ErrorService} from '@services/error/error.service';
import {ErrorInterceptor} from '@interceptors/error.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundPage
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: DwMissingTranslationHandler},
      useDefaultLang: false,
    }),
    LocalizeRouterModule.forRoot(routes, {
      initialNavigation: true,
      useCachedLang: false,
      // Replaced with default browser language
      defaultLangFunction: () => 'eng',
      parser: {
        provide: LocalizeParser,
        useFactory: (translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) =>
          new ManualParserLoader(translate, location, settings, ['eng', 'ukr']),
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },
      alwaysSetPrefix: false,
    }),
    AppRoutingModule,
  ],
  providers: [
    Meta,
    Title,
    ModalService,
    ErrorService,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
