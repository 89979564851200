import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {CookieService} from 'ngx-cookie';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@services/modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private cookie: CookieService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private translate: TranslateService,
    private modalService: ModalService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError((error) => {
        if (error) {
          switch (error.status) {
            case 404: {
              this.Error404Handler(error);
              break;
            }
            case 422: {
              this.Error422Handler(error);
              break;
            }
            case 504: {
              this.translate.get('app.confirm.timeOutError')
                .subscribe(msg => this.modalService.showMsg(msg));
              break;
            }
            default: {
              console.log(error);
              break;
            }
          }
        }
        return throwError(error);
      }));
  }

  Error404Handler(error: HttpErrorResponse): void {
    if (isPlatformBrowser(this.platformId))
      this.router.navigateByUrl('/not-found', {skipLocationChange: true});
  }

  Error422Handler(error: HttpErrorResponse): void {
    if (error?.error?.errors?.quantity[0]) this.modalService.showMsg(error?.error?.errors?.quantity[0]);
    else throwError(error);
  }
}
