import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotFoundPage} from '@components/common';

export const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/'},
  {path: '', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)},
  {path: '**', component: NotFoundPage},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
