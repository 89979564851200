import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MaterialModalComponent} from '@components/common/modals/material-modal/material-modal.component';
import {IServiceButtonProps} from '@shared/helpers';
import {Observable} from 'rxjs';
import {MatDialogConfig} from '@angular/material/dialog/dialog-config';

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  constructor(private dialog: MatDialog) {
  }

  public openDialog(modal: any, config?: MatDialogConfig): MatDialogRef<any> {
    return this.dialog.open(modal, {
      ...config
    });
  }

  public closeAll(): Observable<any> {
    this.dialog.closeAll();
    return this.dialog.afterAllClosed;
  }

  // todo Update props to object & add title
  // tslint:disable-next-line:typedef
  public showMsg(msg: string,
                 width: number | null = 450,
                 buttonsProps: IServiceButtonProps = {
                     cancel: false,
                     cancelTitle: 'cancel',
                     submitTitle: 'ok',
                     closePreviousModal: true
                 }) {
    // TODO 10.02.2021: create store instance for msgs
    // this.modalMsgs = [msg, ...this.modalMsgs];
    if (buttonsProps.closePreviousModal) this.dialog.closeAll();
    const dialog = this.dialog.open(MaterialModalComponent, {
      width: `${width}px`,
      data: {
        msg,
        buttonsProps
      },
    });

    return dialog.afterClosed();
  }
}
