import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IServiceButtonProps} from '@shared/helpers';

@Component({
  selector: 'app-material-modal',
  templateUrl: './material-modal.component.html',
  styleUrls: ['./material-modal.component.scss']
})
export class MaterialModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      { msg: string, buttonsProps: IServiceButtonProps }
  ) {
  }

  ngOnInit(): void {
  }

}
