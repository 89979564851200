<div class="container">
  <div class="privacy-wrap">
    <h1>{{'landing.privacy.title' | translate}}</h1>
    <h2>{{'landing.privacy.subtitle' | translate}}</h2>
    <p>{{'landing.privacy.information' | translate}}</p>
    <h3>{{'landing.privacy.h3' | translate}}</h3>
    <p>{{'landing.privacy.someServices' | translate}}</p>
    <ul>
      <li>{{'landing.privacy.li1' | translate}}</li>
      <li>{{'landing.privacy.li2' | translate}}</li>
      <li>{{'landing.privacy.li3' | translate}}</li>
    </ul>
    <h3>{{'landing.privacy.inServices' | translate}}</h3>
    <p>{{'landing.privacy.addition' | translate}}</p>
    <ul>
      <li><strong>{{'landing.privacy.devInform' | translate}}</strong> - {{'landing.privacy.devDescription' | translate}}</li>
      <li><strong>{{'landing.privacy.logInform' | translate}}</strong> - {{'landing.privacy.logDescription' | translate}}</li>
      <li><strong>{{'landing.privacy.locInform' | translate}}</strong> - {{'landing.privacy.locDescription' | translate}}</li>
      <li><strong>{{'landing.privacy.otherInform' | translate}}</strong> {{'landing.privacy.otherDescription' | translate}}</li>
    </ul>
    <h3>{{'landing.privacy.inThird' | translate}}</h3>
    <p>{{'landing.privacy.mayReceive' | translate}}</p>
    <h3>{{'landing.privacy.otherCollect' | translate}}</h3>
    <p>{{'landing.privacy.otherAlso' | translate}}</p>
    <h2>{{'landing.privacy.subtitle2' | translate}}</h2>
    <p>{{'landing.privacy.anyInform' | translate}}</p>
    <ul>
      <li>{{'landing.privacy.anyInform1' | translate}}</li>
      <li>{{'landing.privacy.anyInform2' | translate}}</li>
      <li>{{'landing.privacy.anyInform3' | translate}}</li>
      <li>{{'landing.privacy.anyInform4' | translate}}</li>
      <li>{{'landing.privacy.anyInform5' | translate}}</li>
    </ul>
    <p>{{'landing.privacy.mayUse' | translate}}</p>
    <ul>
      <li>{{'landing.privacy.mayUse1' | translate}}</li>
      <li>{{'landing.privacy.mayUse2' | translate}}</li>
      <li>{{'landing.privacy.mayUse3' | translate}}</li>
      <li>{{'landing.privacy.mayUse4' | translate}}</li>
      <li>{{'landing.privacy.mayUse5' | translate}}</li>
      <li>{{'landing.privacy.mayUse6' | translate}}</li>
      <li>{{'landing.privacy.mayUse7' | translate}}</li>
    </ul>
    <p>{{'landing.privacy.mayCombine' | translate}}</p>
    <h2>{{'landing.privacy.howDo' | translate}}</h2>
    <p>{{'landing.privacy.howDoDesc' | translate}}</p>
    <h2>{{'landing.privacy.whom' | translate}}</h2>
    <p>{{'landing.privacy.weWill' | translate}}</p>
    <ul>
      <li>{{'landing.privacy.whom1' | translate}}</li>
      <li>{{'landing.privacy.whom2' | translate}}</li>
      <li>{{'landing.privacy.whom3' | translate}}</li>
      <li>{{'landing.privacy.whom4' | translate}}</li>
      <li>{{'landing.privacy.whom5' | translate}}</li>
      <li>{{'landing.privacy.whom6' | translate}}</li>
      <li>{{'landing.privacy.whom7' | translate}}</li>
      <li>{{'landing.privacy.whom8' | translate}}</li>
      <li>{{'landing.privacy.whom9' | translate}}</li>
    </ul>
    <h2>{{'landing.privacy.howSecurity' | translate}}</h2>
    <p>{{'landing.privacy.howSecurityP' | translate}}</p>
    <h2>{{'landing.privacy.changes' | translate}}</h2>
    <p>{{'landing.privacy.changesP' | translate}}</p>
    <h2>{{'landing.privacy.cookies' | translate}}</h2>
    <p>{{'landing.privacy.cookiesP' | translate}}</p>
  </div>
</div>
